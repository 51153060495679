import { types } from 'mobx-state-tree'
import md5 from 'md5'
import { DateTime } from 'luxon'

export const InviteTypes = {
  Participant: 'participant',
  Admin: 'admin'
}

export const InviteModel = types
  .model('InviteModel', {
    participantId: types.string,
    name: types.string,
    email: types.string,
    emailHash: types.string,
    redactedEmail: types.optional(types.string, ''),
    startTimestamp: types.optional(types.string, ''),
    endTimestamp: types.optional(types.string, ''),
    randomiseProbability: types.maybe(types.number)
  })
  .views(self => ({
    get isValid() {
      return self.name.length > 0 && self.email.length > 0 && self.email.includes('@') && self.email.includes('.')
    }
  }))
  .actions(self => ({
    setName(name) {
      self.name = name
    },

    setEmail(email) {
      self.email = email
      self.emailHash = md5(email)

      // Build redacted email
      const parts = email.split('@')
      if (parts.length < 2) {
        return
      }
      const firstBit = parts[0]
      const lastBit = parts[1]

      const notReplaced = firstBit.substring(0, 2)
      let replaced = firstBit.substring(2)

      replaced = replaced.replace(/./g, '*')
      const final = `${notReplaced}${replaced}@${lastBit}`

      self.redactedEmail = final
    },

    setParticipantId(id) {
      self.participantId = id
    },

    setStartTimestamp(timestamp) {
      self.startTimestamp = timestamp
    },

    setEndTimestamp(timestamp) {
      self.endTimestamp = timestamp
    },

    setRandomiseProbability(probability) {
      self.randomiseProbability = probability
    }
  }))

export const InviteListModel = types
  .model('InviteListModel', {
    studyId: types.string,
    inviteType: types.string,
    participants: types.optional(types.array(InviteModel), []),
    startTimestamp: types.optional(types.string, ''),
    endTimestamp: types.optional(types.string, ''),
    sendEmail: types.optional(types.boolean, true),
    skipEmailConfirmation: types.optional(types.boolean, true)
  })
  .views(self => ({
    get isValid() {
      return self.participants.every(item => item.isValid)
    }
  }))
  .actions(self => ({
    reset() {
      self.participants.clear()
      self.skipEmailConfirmation = true
      self.startTimestamp = DateTime.local().toISO()
      self.sendEmail = true
    },

    addParticipantInvite(participantId, name, email, startTimestamp, endTimestamp, randomiseProbability) {
      const emailHash = md5(email)

      self.participants.push({
        participantId,
        name,
        email,
        emailHash,
        startTimestamp,
        endTimestamp,
        randomiseProbability
      })
    },

    removeLastParticipantInvite() {
      self.participants.pop()
    },

    setStartTimestamp(timestamp) {
      self.startTimestamp = timestamp
    },

    setEndTimestamp(timestamp) {
      self.endTimestamp = timestamp
    },

    setSkipEmailConfirmation(skip) {
      self.skipEmailConfirmation = skip
    },

    setSendEmail(sendEmail) {
      self.sendEmail = sendEmail
    }
  }))

// export type IInviteListModel = typeof InviteListModel;
