import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
require('firebase/firestore')

var config = {
  apiKey: process.env.API_KEY,
  authDomain: `${process.env.PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.PROJECT_ID}.firebaseio.com`,
  projectId: `${process.env.PROJECT_ID}`,
  storageBucket: `${process.env.PROJECT_ID}.appspot.com`,
  messagingSenderId: `${process.env.MESSAGING_SENDER_ID}`
}

firebase.initializeApp(config)
export default firebase
