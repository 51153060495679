import React, { Component } from 'react'

/**
 * Load a Component asynchronously for Code-splitting purpose
 * Source: https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
 *
 * @param importComponent - component to load
 * @returns {AsyncComponent} - the output async component
 */
export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props)

      this.state = {
        component: null
      }
    }

    async componentDidMount() {
      const { default: component } = await importComponent()

      this.setState({
        component: component
      })
    }

    render() {
      const C = this.state.component

      return C ? <C {...this.props} /> : null
    }
  }

  return AsyncComponent
}
