import { types, getSnapshot } from 'mobx-state-tree'
import { formatAsTag } from './formatters'
import {
  XYQuestionConditionalModel,
  TextQuestionConditionalModel,
  ChoiceQuestionConditionalModel,
  ChoiceSliderQuestionConditionalModel,
  SliderQuestionConditionalModel,
  EvaluateVariableConditionalModel
} from './conditional_models'
import { QuestionType } from './question_constants'

import shortid from 'shortid'

export const VARIABLE_LIST_DEFAULT = 'DEFAULT'  // Key to mark default values within a study's variable list

export const AssessmentState = {
  Starting: 'starting',
  InProgress: 'in-progress',
  Complete: 'complete',
  Submitted: 'submitted'
}

export const QuestionEditorType = {
  Choice: 'choice',
  XY: 'xy',
  Text: 'text',
  Slider: 'slider',
  ChoiceSlider: 'choice-slider',
  EvaluateVariable: 'evaluate-variable',
  SampleLocation: 'sample-location',
  SampleAudio: 'sample-audio',
  SampleVideo: 'sample-video',
  QuestionSet: 'questionset',
  Assessment: 'assessment'
}

export const QuestionInputType = {
  TEXT: 'Text',
  NUMBER: 'Number',
  EMAIL: 'Email',
  DATE: 'Date',
  TIME: 'Time'
}

export const QuestionInputTypeFormat = {
  DATE: {
    format: 'dd/MMM/yyyy',
    example: '20/Oct/2020'
  },
  TIME: {
    format: 'HH:mm',
    example: '16:30'
  }
}

export const CHOICE_NONE_VALUE = 'NONE'

export const QuestionLinkModel = types.model('QuestionLinkModel', {
  id: types.string,
  label: types.string,
  linkedQuestionSetId: types.string
})

export const QuestionChoiceModel = types
  .model('QuestionChoiceModel', {
    id: types.string,
    text: types.string,
    value: types.string
  })
  .views(self => ({
    get isValid() {
      return self.text.length > 0 && self.value.length > 0
    },
    get isNone() {
      return self.value === CHOICE_NONE_VALUE
    }
  }))
  .actions(self => ({
    setText(text) {
      self.text = text
    },
    setValue(value) {
      self.value = formatAsTag(value)
    }
  }))

export const ChoiceQuestionConfigModel = types
  .model('ChoiceQuestionConfigModel', {
    questionType: types.literal(QuestionType.Choice),
    randomiseDisplayOrder: types.optional(types.boolean, false),
    allowMultipleChoices: types.optional(types.boolean, false),
    choices: types.map(QuestionChoiceModel),
    choiceDisplayOrder: types.array(types.string)
  })
  .actions(self => ({
    addChoice(id, text, value) {
      self.choices.set(id, {
        id,
        text,
        value
      })

      self.choiceDisplayOrder.push(id)
      self.reOrderChoiceDisplayOrder()
    },

    removeChoice(id) {
      self.choices.delete(id)
      self.choiceDisplayOrder.replace(self.choiceDisplayOrder.filter(itemId => itemId !== id))
      self.reOrderChoiceDisplayOrder()
    },

    setAllowMultipleChoices(allow) {
      self.allowMultipleChoices = allow
    },

    setRandomiseDisplayOrder(randomise) {
      self.randomiseDisplayOrder = randomise
    },

    setChoiceDisplayOrder(order) {
      self.choiceDisplayOrder.replace(order)
      self.reOrderChoiceDisplayOrder()
    },

    /**
     * Reorder choices' display order to ensure 'None' option is at the bottom
     */
    reOrderChoiceDisplayOrder() {
      const noneChoice = self.choices.values().find(c => c.isNone)
      if (noneChoice) {
        self.choiceDisplayOrder.replace(self.choiceDisplayOrder.filter(cid => cid !== noneChoice.id))
        self.choiceDisplayOrder.push(noneChoice.id)
      }
    }
  }))

export const TextQuestionConfigModel = types
  .model('TextQuestionConfigModel', {
    questionType: types.literal(QuestionType.Text),
    inputType: types.optional(
      types.enumeration('QuestionInputType', Object.values(QuestionInputType)),
      QuestionInputType.TEXT
    )
  })
  .actions(self => ({
    updateInputType(inputType) {
      self.inputType = inputType
    }
  }))

export const SliderQuestionConfigModel = types
  .model('SliderQuestionConfigModel', {
    questionType: types.literal(QuestionType.Slider),
    minValue: types.optional(types.number, 0),
    maxValue: types.optional(types.number, 10),
    step: types.optional(types.number, 1),
    useBreakPoint: types.optional(types.boolean, false),
    initialValue: types.optional(types.number, 5),
    useInitialValue: types.optional(types.boolean, false),
    randomiseInitialValue: types.optional(types.boolean, false),
    minLabel: types.optional(types.string, ''),
    maxLabel: types.optional(types.string, ''),
    minLabelImage: types.optional(types.string, ''),
    maxLabelImage: types.optional(types.string, ''),
    useImageLabel: types.optional(types.boolean, false),
    hideValueLabel: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setMinLabel(text) {
      self.minLabel = text
    },

    setMinValue(value) {
      self.minValue = value
    },

    setMaxLabel(text) {
      self.maxLabel = text
    },

    setMaxValue(value) {
      self.maxValue = value
    },

    setMinLabelImage(image) {
      self.minLabelImage = image
    },

    setMaxLabelImage(image) {
      self.maxLabelImage = image
    },

    setUseImageLabel(use) {
      self.useImageLabel = use
    },

    setHideValueLabel(hide) {
      self.hideValueLabel = hide
    },

    setStep(step) {
      self.step = step
    },

    setUseBreakPoint(breakPoint) {
      self.useBreakPoint = breakPoint
    },

    setInitialValue(value) {
      self.initialValue = value
    },

    setUseInitialValue(use) {
      self.useInitialValue = use
      if (!use) {
        self.setRandomiseInitialValue(false)
      }
    },

    setRandomiseInitialValue(randomise) {
      self.randomiseInitialValue = randomise
    }
  }))

export const XYLayoutTypes = {
  Corner: 'corner',
  Centered: 'centered',
  Side: 'side'
}
export const XYQuestionConfigModel = types
  .model('XYQuestionConfigModel', {
    questionType: types.literal(QuestionType.XY),

    hideGrid: types.optional(types.boolean, false),
    hideLabels: types.optional(types.boolean, false),
    gridCols: types.optional(types.number, 15),
    gridRows: types.optional(types.number, 15),
    layoutType: types.optional(
      types.enumeration('LayoutType', [XYLayoutTypes.Corner, XYLayoutTypes.Centered, XYLayoutTypes.Side]),
      XYLayoutTypes.Corner
    ),

    xMinValue: types.optional(types.number, 0),
    xMinLabel: types.optional(types.string, ''),

    xMaxValue: types.optional(types.number, 10),
    xMaxLabel: types.optional(types.string, ''),

    yMinValue: types.optional(types.number, 0),
    yMinLabel: types.optional(types.string, ''),

    yMaxValue: types.optional(types.number, 10),
    yMaxLabel: types.optional(types.string, ''),

    backgroundImage: types.maybe(types.string),
    backgroundImageLabel: types.optional(types.string, '')
  })
  .actions(self => ({
    setHideGrid(value) {
      self.hideGrid = value
    },

    setHideLabels(value) {
      self.hideLabels = value
    },

    setGridCols(value) {
      self.gridCols = value
    },

    setGridRows(value) {
      self.gridRows = value
    },

    setLayoutType(value) {
      self.layoutType = value
    },

    setXMinValue(value) {
      self.xMinValue = value
    },

    setXMaxValue(value) {
      self.xMaxValue = value
    },

    setXMinLabel(label) {
      self.xMinLabel = label
    },

    setXMaxLabel(label) {
      self.xMaxLabel = label
    },

    setYMinValue(value) {
      self.yMinValue = value
    },

    setYMaxValue(value) {
      self.yMaxValue = value
    },

    setYMinLabel(label) {
      self.yMinLabel = label
    },

    setYMaxLabel(label) {
      self.yMaxLabel = label
    },

    setBackgroundImage(url, label) {
      self.backgroundImage = url
      self.backgroundImageLabel = label
    }
  }))

//
// CHOICE SLIDER
//

export const ChoiceSliderQuestionConfigModel = types
  .model('ChoiceSliderQuestionConfigModel', {
    questionType: types.literal(QuestionType.ChoiceSlider),

    minValue: types.optional(types.number, 0),
    maxValue: types.optional(types.number, 10),
    step: types.optional(types.number, 1),
    useBreakPoint: types.optional(types.boolean, false),

    initialValue: types.optional(types.number, 5),
    useInitialValue: types.optional(types.boolean, false),
    randomiseInitialValue: types.optional(types.boolean, false),

    minLabel: types.optional(types.string, ''),
    maxLabel: types.optional(types.string, ''),
    minLabelImage: types.optional(types.string, ''),
    maxLabelImage: types.optional(types.string, ''),
    useImageLabel: types.optional(types.boolean, false),
    hideValueLabel: types.optional(types.boolean, false),

    randomiseDisplayOrder: types.optional(types.boolean, false),
    allowMultipleChoices: types.optional(types.boolean, false),
    choices: types.map(QuestionChoiceModel),
    choiceDisplayOrder: types.array(types.string)
  })
  .actions(self => ({
    // Slider
    setMinLabel(text) {
      self.minLabel = text
    },

    setMinValue(value) {
      self.minValue = value
    },

    setMaxLabel(text) {
      self.maxLabel = text
    },

    setMaxValue(value) {
      self.maxValue = value
    },

    setMinLabelImage(image) {
      self.minLabelImage = image
    },

    setMaxLabelImage(image) {
      self.maxLabelImage = image
    },

    setUseImageLabel(use) {
      self.useImageLabel = use
    },

    setHideValueLabel(hide) {
      self.hideValueLabel = hide
    },

    setStep(step) {
      self.step = step
    },

    setUseBreakPoint(breakPoint) {
      self.useBreakPoint = breakPoint
    },

    setInitialValue(value) {
      self.initialValue = value
    },

    setUseInitialValue(use) {
      self.useInitialValue = use
      if (!use) {
        self.setRandomiseInitialValue(false)
      }
    },

    setRandomiseInitialValue(randomise) {
      self.randomiseInitialValue = randomise
    },

    // Choice
    addChoice(id, text, value) {
      self.choices.set(id, {
        id,
        text,
        value
      })

      self.choiceDisplayOrder.push(id)
      self.reOrderChoiceDisplayOrder()
    },

    removeChoice(id) {
      self.choices.delete(id)
      self.choiceDisplayOrder.replace(self.choiceDisplayOrder.filter(itemId => itemId !== id))
      self.reOrderChoiceDisplayOrder()
    },

    setAllowMultipleChoices(allow) {
      self.allowMultipleChoices = allow
    },

    setRandomiseDisplayOrder(randomise) {
      self.randomiseDisplayOrder = randomise
    },

    setChoiceDisplayOrder(order) {
      self.choiceDisplayOrder.replace(order)
      self.reOrderChoiceDisplayOrder()
    },

    /**
     * Reorder choices' display order to ensure 'None' option is at the bottom
     */
    reOrderChoiceDisplayOrder() {
      const noneChoice = self.choices.values().find(c => c.isNone)
      if (noneChoice) {
        self.choiceDisplayOrder.replace(self.choiceDisplayOrder.filter(cid => cid !== noneChoice.id))
        self.choiceDisplayOrder.push(noneChoice.id)
      }
    }
  }))

//
// EVALUATEVARIABLES
//

export const EvaluateVariableQuestionConfigModel = types
  .model('EvaluateVariableQuestionConfigModel', {
    questionType: types.literal(QuestionType.EvaluateVariable)
  })
  .actions(() => ({}))

//
// SAMPLE GEOLOCATION
//

export const SampleLocationQuestionConfigModel = types
  .model('SampleLocationQuestionConfigModel', {
    questionType: types.literal(QuestionType.SampleLocation)
  })
  .actions(() => ({}))

//
// QUESTION MODEL
//

export const QuestionModel = types
  .model('QuestionModel', {
    id: types.identifier(),
    text: types.string,
    tag: types.string,
    questionType: types.enumeration('QuestionType', [
      QuestionType.Choice,
      QuestionType.XY,
      QuestionType.Text,
      QuestionType.Slider,
      QuestionType.ChoiceSlider,
      QuestionType.EvaluateVariable,
      QuestionType.SampleLocation
    ]),
    config: types.union(
      snapshot => {
        if (snapshot.questionType === QuestionType.Choice) {
          return ChoiceQuestionConfigModel
        }
        if (snapshot.questionType === QuestionType.Text) {
          return TextQuestionConfigModel
        }
        if (snapshot.questionType === QuestionType.Slider) {
          return SliderQuestionConfigModel
        }
        if (snapshot.questionType === QuestionType.XY) {
          return XYQuestionConfigModel
        }
        if (snapshot.questionType === QuestionType.ChoiceSlider) {
          return ChoiceSliderQuestionConfigModel
        }
        if (snapshot.questionType === QuestionType.EvaluateVariable) {
          return EvaluateVariableQuestionConfigModel
        }
        if (snapshot.questionType === QuestionType.SampleLocation) {
          return SampleLocationQuestionConfigModel
        }
      },
      ChoiceQuestionConfigModel,
      TextQuestionConfigModel,
      SliderQuestionConfigModel,
      XYQuestionConfigModel,
      ChoiceSliderQuestionConfigModel,
      EvaluateVariableQuestionConfigModel,
      SampleLocationQuestionConfigModel
    ),
    conditionals: types.map(
      types.union(
        snapshot => {
          if (snapshot.type === QuestionType.Text) {
            return TextQuestionConditionalModel
          }
          if (snapshot.type === QuestionType.Choice) {
            return ChoiceQuestionConditionalModel
          }
          if (snapshot.type === QuestionType.ChoiceSlider) {
            return ChoiceSliderQuestionConditionalModel
          }
          if (snapshot.type === QuestionType.Slider) {
            return SliderQuestionConditionalModel
          }
          if (snapshot.type === QuestionType.XY) {
            return XYQuestionConditionalModel
          }
          if (snapshot.type === QuestionType.EvaluateVariable) {
            return EvaluateVariableConditionalModel
          }
        },
        TextQuestionConditionalModel,
        ChoiceQuestionConditionalModel,
        ChoiceSliderQuestionConditionalModel,
        SliderQuestionConditionalModel,
        EvaluateVariableConditionalModel
      )
    )
  })
  .actions(self => ({
    updateText(text) {
      self.text = text
    },

    updateTag(text) {
      self.tag = text
    },

    addTextConditional(containerId, questionSetTitle, value) {
      const id = shortid.generate()

      self.conditionals.set(id, {
        id: id,
        type: QuestionType.Text,
        command: 'insert',
        containerId: containerId,
        questionSetTitle: questionSetTitle,
        comparator: 'includes',
        value: value
      })

      return id
    },

    addChoiceConditional(containerId, questionSetTitle, selectedChoices) {
      const id = shortid.generate()

      let choices = {}
      for (let choice of selectedChoices.entries()) {
        let value = choice[1]
        choices[value.value] = true
      }

      self.conditionals.set(id, {
        id: id,
        type: QuestionType.Choice,
        command: 'insert',
        containerId: containerId,
        questionSetTitle: questionSetTitle,
        comparator: 'includes-any',
        selectedChoices: choices
      })

      return id
    },

    addChoiceSliderConditional(containerId, questionSetTitle, selectedChoices, minValue, maxValue) {
      const id = shortid.generate()

      let choices = {}
      for (let choice of selectedChoices.entries()) {
        let value = choice[1]
        choices[value.value] = true
      }

      self.conditionals.set(id, {
        id: id,
        type: QuestionType.ChoiceSlider,
        command: 'insert',
        containerId: containerId,
        questionSetTitle: questionSetTitle,
        comparator: 'includes-any',
        selectedChoices: choices,
        minValueComparator: 'greater-than',
        maxValueComparator: 'less-than',
        minValue: minValue,
        maxValue: maxValue
      })

      return id
    },

    addSliderConditional(containerId, questionSetTitle, minValue, maxValue) {
      const id = shortid.generate()

      self.conditionals.set(id, {
        id: id,
        type: QuestionType.Slider,
        command: 'insert',
        containerId: containerId,
        questionSetTitle: questionSetTitle,
        minValueComparator: 'greater-than',
        maxValueComparator: 'less-than',
        minValue: minValue,
        maxValue: maxValue
      })

      return id
    },

    addXYConditional(containerId, questionSetTitle, xMinValue, xMaxValue, yMinValue, yMaxValue) {
      const id = shortid.generate()

      self.conditionals.set(id, {
        id: id,
        type: QuestionType.XY,
        command: 'insert',
        containerId: containerId,
        questionSetTitle: questionSetTitle,
        comparator: 'contains',
        xMinValue: xMinValue,
        xMaxValue: xMaxValue,
        yMinValue: yMinValue,
        yMaxValue: yMaxValue
      })

      return id
    },

    addEvaluateVariableConditional(containerId, questionSetTitle, variableId, comparator, value) {
      const id = shortid.generate()

      self.conditionals.set(id, {
        id: id,
        type: QuestionType.EvaluateVariable,
        command: 'insert',
        containerId: containerId,
        questionSetTitle: questionSetTitle,
        comparator: comparator,
        variableId: variableId,
        value: value
      })

      return id
    },

    /**
     * Remove conditional and its relating container if required
     * @param id - conditional id
     * @param assessment - the assessment (survey) containing the relating container. If null, will not remove the container
     */
    removeConditional(id, assessment) {
      if (assessment) {
        assessment.deleteContainer(self.conditionals.get(id).containerId)
      }
      self.conditionals.delete(id)
    },

    clearConditionals(assessment) {
      self.conditionals.values().forEach(cond => {
        self.removeConditional(cond.id, assessment)
      })
    },

    cloneConditional(id, assessment) {
      const conditionalToClone = getSnapshot(self.conditionals.get(id))

      const clonedConditional = Object.assign({}, conditionalToClone)
      const newId = shortid.generate()
      clonedConditional.id = newId
      clonedConditional.containerId = assessment.cloneContainer(clonedConditional.containerId)

      self.conditionals.set(newId, clonedConditional)

      return newId
    }
  }))

export const QuestionSetModel = types
  .model('QuestionSetModel', {
    id: types.identifier(),
    title: types.string,
    randomiseDisplayOrder: types.optional(types.boolean, false),
    useRandomQuestionSubset: types.optional(types.boolean, false),
    randomQuestionSubsetNumber: types.maybe(types.number),
    useRandomiseProbability: types.optional(types.boolean, false),
    prevQuestionSetId: types.maybe(types.string, null),
    nextQuestionSetId: types.maybe(types.string, null),
    questions: types.map(QuestionModel),
    questionDisplayOrder: types.array(types.string),
    openLinkId: types.maybe(types.string)
  })
  .actions(self => ({
    updateTitle(text) {
      self.title = text
    },

    toggleLink(linkId) {
      self.openLinkId = self.openLinkId !== linkId ? linkId : null
    },

    cloneQuestion(id, assessment) {
      const questionToClone = Object.assign({}, getSnapshot(self.questions.get(id)))

      const newId = shortid.generate()
      questionToClone.id = newId
      questionToClone.tag = `${questionToClone.tag}_CLONE_${newId}`
      const clonedQuestion = QuestionModel.create(questionToClone)

      clonedQuestion.conditionals.values().forEach(cond => {
        clonedQuestion.cloneConditional(cond.id, assessment)
        clonedQuestion.removeConditional(cond.id)
      })

      self.questions.set(newId, clonedQuestion)
      self.questionDisplayOrder.push(newId)

      return newId
    },

    clearQuestions(assessment) {
      self.questions.values().forEach(q => {
        self.deleteQuestion(q.id, assessment)
      })
    },

    deleteQuestion(id, assessment) {
      self.questionDisplayOrder.remove(id)
      self.questions.get(id).clearConditionals(assessment)
      self.questions.delete(id)
    },

    updateQuestionDisplayOrder(newDisplayOrder) {
      self.questionDisplayOrder.replace(newDisplayOrder)
    },

    setPrevQuestionSetId(prevQuestionSetId) {
      self.prevQuestionSetId = prevQuestionSetId
    },

    setNextQuestionSetId(nextQuestionSetId) {
      self.nextQuestionSetId = nextQuestionSetId
    },

    setRandomiseDisplayOrder(randomise) {
      self.randomiseDisplayOrder = randomise
      if (randomise) {
        self.setUseRandomiseProbability(false)
      }
    },

    setUseRandomQuestionSubset(randomise) {
      self.useRandomQuestionSubset = randomise
      self.setRandomQuestionSubsetNumber(randomise ? self.questionDisplayOrder.length : null)
      if (!randomise) {
        self.setUseRandomiseProbability(false)
      }
    },

    setRandomQuestionSubsetNumber(number) {
      self.randomQuestionSubsetNumber = number
    },

    setUseRandomiseProbability(val) {
      self.useRandomiseProbability = val
      if (val) {
        self.setRandomiseDisplayOrder(false)
        self.setRandomQuestionSubsetNumber(1)
      }
    },

    addChoiceQuestion(id, text, tag, choiceId, choiceText, choiceValue) {
      let choices = {}
      let choiceDisplayOrder = []

      // Choice
      if (choiceId) {
        const choice = QuestionChoiceModel.create({
          id: choiceId,
          text: choiceText,
          value: choiceValue
        })
        choices[choiceId] = choice
        choiceDisplayOrder.push(choiceId)
      }

      // Choice Config
      const config = ChoiceQuestionConfigModel.create({
        choices: choices,
        choiceDisplayOrder: choiceDisplayOrder,
        questionType: QuestionType.Choice
      })

      // Choice Type question
      const question = QuestionModel.create({
        questionType: QuestionType.Choice,
        id,
        text,
        tag,
        conditionals: {},
        config: config
      })

      self.questions.set(id, getSnapshot(question))
      self.questionDisplayOrder.push(id)
    },

    addTextQuestion(id, text, tag) {
      // Choice Config
      const config = TextQuestionConfigModel.create({
        questionType: QuestionType.Text
      })

      // Choice Type question
      const question = QuestionModel.create({
        questionType: QuestionType.Text,
        id,
        text,
        tag,
        conditionals: {},
        config: config
      })

      self.questions.set(id, getSnapshot(question))
      self.questionDisplayOrder.push(id)
    },

    addSliderQuestion(id, text, tag) {
      // Choice Config
      const config = SliderQuestionConfigModel.create({
        questionType: QuestionType.Slider
      })

      // Choice Type question
      const question = QuestionModel.create({
        questionType: QuestionType.Slider,
        id,
        text,
        tag,
        conditionals: {},
        config: config
      })

      self.questions.set(id, getSnapshot(question))
      self.questionDisplayOrder.push(id)
    },

    addXYQuestion(id, text, tag) {
      // XY Config
      const config = XYQuestionConfigModel.create({
        questionType: QuestionType.XY
      })

      // XY Type question
      const question = QuestionModel.create({
        questionType: QuestionType.XY,
        id,
        text,
        tag,
        conditionals: {},
        config: config
      })

      self.questions.set(id, getSnapshot(question))
      self.questionDisplayOrder.push(id)
    },

    addChoiceSliderQuestion(id, text, tag) {
      let choices = {}
      let choiceDisplayOrder = []

      // ChoiceSlider Config
      const config = ChoiceSliderQuestionConfigModel.create({
        choices: choices,
        choiceDisplayOrder: choiceDisplayOrder,
        questionType: QuestionType.ChoiceSlider
      })

      // ChoiceSlider Type question
      const question = QuestionModel.create({
        questionType: QuestionType.ChoiceSlider,
        id,
        text,
        tag,
        conditionals: {},
        config: config
      })

      self.questions.set(id, getSnapshot(question))
      self.questionDisplayOrder.push(id)
    },

    addEvaluateVariableQuestion(id, tag) {
      // ChoiceSlider Config
      const config = EvaluateVariableQuestionConfigModel.create({
        questionType: QuestionType.EvaluateVariable
      })

      // ChoiceSlider Type question
      const question = QuestionModel.create({
        questionType: QuestionType.EvaluateVariable,
        id,
        text: '[EVALUATE VARIABLE]',
        tag,
        conditionals: {},
        config: config
      })

      self.questions.set(id, getSnapshot(question))
      self.questionDisplayOrder.push(id)
    },

    addSampleLocationQuestion(id, tag) {
      // ChoiceSlider Config
      const config = SampleLocationQuestionConfigModel.create({
        questionType: QuestionType.SampleLocation
      })

      // ChoiceSlider Type question
      const question = QuestionModel.create({
        questionType: QuestionType.SampleLocation,
        id,
        text: '[SAMPLE LOCATION]',
        tag,
        conditionals: {},
        config: config
      })

      self.questions.set(id, getSnapshot(question))
      self.questionDisplayOrder.push(id)
    }
  }))

export const QuestionSetContainerModel = types
  .model('QuestionSetContainerModel', {
    id: types.identifier(),
    randomiseDisplayOrder: types.optional(types.boolean, false),
    questionSets: types.map(QuestionSetModel),
    questionSetDisplayOrder: types.array(types.string)
  })
  .actions(self => ({
    deleteQuestionSet(id, assessment) {
      self.questionSetDisplayOrder.remove(id)
      self.questionSets.get(id).clearQuestions(assessment)
      self.questionSets.delete(id)
    },

    clearQuestionSets(assessment) {
      self.questionSets.values().forEach(qs => {
        self.deleteQuestionSet(qs.id, assessment)
      })
    },

    updateQuestionSetDisplayOrder(newDisplayOrder) {
      self.questionSetDisplayOrder.replace(newDisplayOrder)
    },

    setRandomiseDisplayOrder(randomise) {
      self.randomiseDisplayOrder = randomise
    },

    addQuestionSet(title, randomiseDisplayOrder) {
      const id = shortid.generate()

      // Choice Config
      const questionSet = QuestionSetModel.create({
        id: id,
        title: title,
        randomiseDisplayOrder: randomiseDisplayOrder,
        questions: {},
        questionDisplayOrder: []
      })

      self.questionSets.set(id, getSnapshot(questionSet))
      self.questionSetDisplayOrder.push(id)

      return id
    },

    cloneQuestionSet(id, assessment) {
      const questionSetToClone = Object.assign({}, getSnapshot(self.questionSets.get(id)))

      const newId = shortid.generate()
      questionSetToClone.id = newId
      questionSetToClone.title = `${questionSetToClone.title} Clone ${newId}`
      const clonedQuestionSet = QuestionSetModel.create(questionSetToClone)

      clonedQuestionSet.questionDisplayOrder.forEach(qId => {
        clonedQuestionSet.cloneQuestion(qId, assessment)
        clonedQuestionSet.deleteQuestion(qId)
      })

      self.questionSets.set(newId, getSnapshot(clonedQuestionSet))
      self.questionSetDisplayOrder.push(newId)

      return newId
    }
  }))

export const AssessmentModel = types
  .model('AssessmentModel', {
    id: types.identifier(),
    displayName: types.string,

    containers: types.optional(types.map(QuestionSetContainerModel), {}),
    rootContainerId: types.maybe(types.string),

    enableScheduleTrigger: types.optional(types.boolean, false),
    scheduleId: types.maybe(types.string, '-1'),

    enableAdhocTrigger: types.optional(types.boolean, false),
    adHocTriggerExpiryMinutes: types.optional(types.number, 0),

    enableExternalTrigger: types.optional(types.boolean, false),
    externalTriggerId: types.optional(types.string, '')
  })
  .views(self => ({
    get rootContainer() {
      return self.rootContainerId && self.rootContainerId.length > 0 ? self.containers.get(self.rootContainerId) : null
    },
    get scheduleIsEnabled() {
      return self.enableScheduleTrigger && self.scheduleId !== null && self.scheduleId.length > 0
    }
  }))
  .actions(self => ({
    getContainer(id) {
      return self.containers.get(id)
    },

    addContainer(id, initialQuestionSet) {
      let questionSets = {}
      let questionSetDisplayOrder = []

      if (initialQuestionSet) {
        questionSets[initialQuestionSet.id] = initialQuestionSet
        questionSetDisplayOrder.push(initialQuestionSet.id)
      }

      const newContainer = QuestionSetContainerModel.create({
        id: id,
        randomiseDisplayOrder: false,
        questionSets: questionSets,
        questionSetDisplayOrder: questionSetDisplayOrder
      })
      self.containers.set(id, newContainer)

      return newContainer
    },

    deleteContainer(id) {
      self.containers.get(id).clearQuestionSets(self)
      self.containers.delete(id)
    },

    clearContainers() {
      self.containers = {}
    },

    cloneContainer(id) {
      const containerToClone = Object.assign({}, getSnapshot(self.containers.get(id)))

      const newId = shortid.generate()
      containerToClone.id = newId
      const clonedContainer = QuestionSetContainerModel.create(containerToClone)

      clonedContainer.questionSetDisplayOrder.forEach(qsId => {
        clonedContainer.cloneQuestionSet(qsId, self)
        clonedContainer.deleteQuestionSet(qsId)
      })

      self.containers.set(newId, getSnapshot(clonedContainer))

      return newId
    },

    addQuestionSet(id, title) {
      self.questionSets.set(id, {
        id,
        title,
        questions: {},
        questionDisplayOrder: []
      })
    },

    deleteQuestionSet(id) {
      const questionSet = self.questionSets.get(id)

      // Unlink from previous
      if (questionSet.prevQuestionSetId) {
        const prevQuestionSet = self.questionSets.get(questionSet.prevQuestionSetId)
        prevQuestionSet.setNextQuestionSetId(null)
      }

      // Unlink from next
      if (questionSet.nextQuestionSetId) {
        const nextQuestionSet = self.questionSets.get(questionSet.nextQuestionSetId)
        nextQuestionSet.setPrevQuestionSetId(null)
      }

      self.questionSets.delete(id)
    },

    setRootContainerId(rootId) {
      self.rootContainerId = rootId
    },

    updateDisplayName(text) {
      self.displayName = text
    },

    setSchedule(scheduleId) {
      self.scheduleId = scheduleId
    },

    setEnableScheduleTrigger(enable) {
      self.enableScheduleTrigger = enable
    },

    setEnableAdHocTrigger(enabled) {
      self.enableAdhocTrigger = enabled
    },

    setAdHocTriggerExpiryMinutes(minutes) {
      self.adHocTriggerExpiryMinutes = minutes
    },

    setEnableExternalTrigger(enabled) {
      self.enableExternalTrigger = enabled
    }
  }))

// Assessments Model
export const AssessmentsModel = types
  .model('AssessmentsModel', {
    assessments: types.map(AssessmentModel)
  })
  .views(self => ({
    get length() {
      return self.assessments.length
    },
    get assessmentsArray() {
      return self.assessments.values()
    }
  }))
  .actions(self => ({
    mergeAssessments(items) {
      self.assessments.replace(items)
    },
    updateAssessment(item) {
      self.assessments.set(item.id, item)
    },
    removeAssessment(id) {
      self.assessments.delete(id)
    },
    cloneAssessment(id) {
      const assessmentToClone = Object.assign({}, getSnapshot(self.assessments.get(id)))

      const newId = shortid.generate()
      assessmentToClone.id = newId
      assessmentToClone.displayName = `${assessmentToClone.displayName} Clone ${newId}`
      const clonedAssessment = AssessmentModel.create(assessmentToClone)
      const oldRootContainerId = clonedAssessment.rootContainerId

      const clonedRContainerId = clonedAssessment.cloneContainer(oldRootContainerId)
      clonedAssessment.setRootContainerId(clonedRContainerId)
      clonedAssessment.deleteContainer(oldRootContainerId)

      return getSnapshot(clonedAssessment)
    },
    get(id) {
      return self.assessments.get(id)
    }
  }))
