import { types } from 'mobx-state-tree'

export const ParticipantState = {
  Active: 'active',
  Stopped: 'stopped',
  Archived: 'archived'
}

export const ParticipantPermission = {
  User: 'user',
  Admin: 'admin'
}

export const ParticipantChartsPreview = {
  Active: 'active',
  Stopped: 'stopped'
}

// Prefix added to participant ID when exporting (prevents excel from removing leading 0 in IDs)
export const ParticipantExportIdPrefix = 's'

// PARTICIPANT

export const PendingParticipantModel = types.model('PendingParticipantModel', {
  participantId: types.identifier(),
  redactedEmail: types.optional(types.string, '*****'),
  invitedTimestamp: types.optional(types.string, '')
})

export const ParticipantSurveyConfig = types
  .model('ParticipantSurveyConfig', {
    surveyId: types.string,
    isEnabled: types.optional(types.boolean, false),
    customScheduleId: types.maybe(types.string)
  })
  .views(() => ({}))
  .actions(self => ({
    toggleEnabled() {
      self.isEnabled = !self.isEnabled
    },
    setCustomScheduleId(id) {
      self.customScheduleId = id
    }
  }))

export const ParticipantModel = types
  .model('ParticipantModel', {
    participantId: types.identifier(),
    compliance: types.number,
    state: types.string,
    lastUploadTimestamp: types.maybe(types.string),
    lastSyncTimestamp: types.maybe(types.string),
    lastHeartbeatTimestamp: types.maybe(types.string),
    platform: types.string,
    startTimestamp: types.string,
    endTimestamp: types.maybe(types.string),
    progress: types.optional(types.number, 0),
    xp: types.optional(types.number, 0),
    streakDays: types.optional(types.number, 0),
    surveyConfigs: types.optional(types.map(ParticipantSurveyConfig), {}),
    numAdhocResponses: types.optional(types.number, 0),
    numCompletedResponses: types.optional(types.number, 0),
    numReceivedResponses: types.optional(types.number, 0),
    permission: types.optional(types.string, ParticipantPermission.User),
    chartsPreview: types.optional(types.string, ParticipantChartsPreview.Stopped),
    chartsPreviewCode: types.maybe(types.string),
    randomiseProbability: types.maybe(types.number)
  })
  .views(() => ({}))
  .actions(self => ({
    getConfig(surveyId) {
      return self.surveyConfigs.get(surveyId)
    }
  }))

export const ParticipantsModel = types
  .model('ParticipantsModel', {
    pendingParticipants: types.map(PendingParticipantModel),
    participants: types.map(ParticipantModel)
  })
  .actions(self => ({
    setParticipants(participants) {
      self.participants.replace(participants)
    },
    setPendingParticipants(participants) {
      self.pendingParticipants.replace(participants)
    },
    removeParticipants(ids) {
      for (const id of ids) {
        self.participants.delete(id)
      }
    },
  }))
