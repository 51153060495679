import axios from 'axios'

let baseURL = `https://us-central1-${process.env.PROJECT_ID}.cloudfunctions.net`

// if (process.env.NODE_ENV === 'development') {
//   baseURL = `http://localhost:5000/${process.env.PROJECT_ID}/us-central1`
// }

const instance = axios.create({
  baseURL: baseURL
})

export default instance
