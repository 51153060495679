import { types } from 'mobx-state-tree'

export const VariableValueType = {
  Numeric: 'numeric',
  String: 'string'
}

export const VariableSourceType = {
  Computed: 'computed',
  Custom: 'custom'
}

export const VariableModel = types
  .model('VariableModel', {
    name: types.string,
    type: types.string,
    valueType: types.string,
    stringValue: types.string,
    numericValue: types.number
  })
  .views(self => ({
    get value() {
      return self.valueType === VariableValueType.Numeric
        ? self.numericValue
        : self.stringValue
    }
  }))
  .actions(() => ({}))

export const VariablesModel = types
  .model('VariablesModel', {
    variables: types.map(VariableModel)
  })
  .views(() => ({}))
  .actions(self => ({
    addVariable(name, type, valueType, value) {
      if (valueType === VariableValueType.Numeric) {
        const variable = VariableModel.create({
          name,
          type,
          valueType,
          numericValue: parseInt(value, 10),
          stringValue: ''
        })
        self.variables.set(name, variable)
        return variable
      } else if (valueType === VariableValueType.String) {
        const variable = VariableModel.create({
          name,
          type,
          valueType,
          stringValue: value,
          numericValue: 0
        })
        self.variables.set(name, variable)
        return variable
      }
    },

    removeVariable(name) {
      self.variables.delete(name)
    },

    mergeVariables(variables) {
      self.variables.replace(variables)
    }
  }))
