import React, { Component } from 'react'
import { observer, Provider } from 'mobx-react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import axios from 'axios'

import api from './stores/api'
import firebase from './stores/firebase_connection'

import Loader from './components/loader'
import ProtectedRoute from './components/protected_route'

import asyncComponent from './components/async_component'
import { StudiesModel, StudyModel, StudyState, StudyVersionModel } from './shared_models/study_models'
import { ParticipantsModel } from './shared_models/participant_models'
import { SchedulesModel } from './shared_models/schedule_models'
import { AssessmentsModel } from './shared_models/assessment_models'
import { AdminListModel } from './shared_models/admin_models'
import { DashboardModel } from './shared_models/dashboard_models'
import { VariablesModel } from './shared_models/variable_models'
import { GeneralModel } from './shared_models/general_models'
import { ExportTaskModel, ExportTaskState } from './shared_models/exporter_models'
import { EventsModel } from './shared_models/event_models'

import DataStore from './stores/data_store'
import AuthStore from './stores/auth_store'

import './app.css'

const SignIn = asyncComponent(() => import('./screens/signin'))
const ActivateAccount = asyncComponent(() => import('./screens/activate_account'))
const ActivateAccountPasswordless = asyncComponent(() => import('./screens/activate_account_passwordless'))
const AccountActivated = asyncComponent(() => import('./screens/account_activated'))
const AppContent = asyncComponent(() => import('./screens/app_content'))
const ChartsPreview = asyncComponent(() => import('./screens/participant_charts_preview'))

@observer
export default class App extends Component {
  constructor(props) {
    super(props)

    this.studiesModel = StudiesModel.create({ studies: {} })

    this.studyModel = StudyModel.create({
      id: '',
      displayName: '',
      description: '',
      contactName: '',
      contactPhoneNumber: '',
      contactEmailAddress: '',
      versions: {},
      publishedVersionId: null,
      editingVersionId: 1,
      state: StudyState.Active,
      compliance: 0,
      lastUploadTimestamp: null,
      ownerId: null,
      lastSyncTimestamp: null
    })

    this.studyVersionModel = StudyVersionModel.create({
      id: 1,
      schedules: {},
      assessments: {}
    })
    this.participantsModel = ParticipantsModel.create({
      participants: {},
      pendingParticipants: {}
    })
    this.schedulesModel = SchedulesModel.create({ schedules: {} })
    this.assessmentsModel = AssessmentsModel.create({ assessments: {} })
    this.adminListModel = AdminListModel.create({ items: [] })
    this.dashboardModel = DashboardModel.create({ activeParticipants: [] })
    this.variablesModel = VariablesModel.create({ variables: {} })
    this.generalModel = GeneralModel.create({ systemNotification: '' })
    this.exportTaskModel = ExportTaskModel.create({
      id: '',
      timezoneName: '',
      userId: '',
      studyId: '',
      studyVersion: 0,
      addedTimestampUnix: 0,
      outputFilename: '',
      state: ExportTaskState.Idle,
      exportProgress: 0,
      downloadUrl: ''
    })
    this.eventsModel = EventsModel.create({ events: {} })

    this.dataStore = new DataStore(
      firebase,
      api,
      this.studiesModel,
      this.studyModel,
      this.studyVersionModel,
      this.participantsModel,
      this.schedulesModel,
      this.assessmentsModel,
      this.adminListModel,
      this.dashboardModel,
      this.variablesModel,
      this.exportTaskModel,
      this.eventsModel,
      this.generalModel
    )

    this.authStore = new AuthStore(firebase, this.onSignInStateChanged, this.onAuthTokenChanged)
  }

  onSignInStateChanged = (user, wasSignedIn, isSignedIn) => {
    // Watch data on sign in
    if (isSignedIn) {
      user.getIdToken().then(token => {
        // Now we have the token, set up the api
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

        // Now connect to the server
        this.dataStore.connectToServer(user)
      })
    } else {
      this.dataStore.disconnectFromServer()
    }

    if (wasSignedIn && !isSignedIn) {
      alert('Signed out')
    }
  }

  onAuthTokenChanged = user => {
    if (!user) {
      return
    }

    user.getIdToken().then(token => {
      // Now we have the token, set up the api
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    })
  }

  render() {
    if (!this.authStore.isReady) {
      return <Loader />
    }

    return (
      <Provider
        dataStore={this.dataStore}
        authStore={this.authStore}
        studiesModel={this.studiesModel}
        studyModel={this.studyModel}
        studyVersionModel={this.studyVersionModel}
        participantsModel={this.participantsModel}
        schedulesModel={this.schedulesModel}
        assessmentsModel={this.assessmentsModel}
        adminListModel={this.adminListModel}
        dashboardModel={this.dashboardModel}
        variablesModel={this.variablesModel}
        exportTaskModel={this.exportTaskModel}
        eventsModel={this.eventsModel}
        generalModel={this.generalModel}
      >
        <Router>
          <div className='app'>
            <Switch>
              <Route exact path='/signin' component={SignIn} />
              <Route exact path='/activate/:inviteToken' component={ActivateAccountPasswordless} />
              <Route exact path='/activate-agent/:inviteToken' component={ActivateAccount} />
              <Route exact path='/activated' component={AccountActivated} />
              <Route exact path='/charts/studies/:studyId/participants/:participantId' component={ChartsPreview} />
              <ProtectedRoute path='/' component={AppContent} isAuthenticated={this.authStore.isSignedIn} />
            </Switch>
          </div>
        </Router>
      </Provider>
    )
  }
}
