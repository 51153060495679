import { types, applySnapshot } from 'mobx-state-tree'

/* Constants used for default model values */
const DEFAULT_COMPLIANCE = 0.5
const DEFAULT_SYNC_TIME = 48 // in hours

export const StudyMode = {
  ReadOnly: 'readonly',
  Default: 'default'
}

export const StudyState = {
  Active: 'active',
  Stopped: 'stopped'
}

/* List of factors where user will be alerted if below a certain threshold */
export const alertTypes = {
  COMPLIANCE: 'Compliance',
  SYNC_TIME: 'Sync Time'
}

/* This model keeps the list of alert threshold values specific to the study */
export const AlertThresholdsModel = types.model('AlertThresholdsModel', {
  compliance: types.optional(types.number, DEFAULT_COMPLIANCE),
  syncTime: types.optional(types.number, DEFAULT_SYNC_TIME)
})

// STUDY

export const StudyModel = types
  .model('StudyModel', {
    id: types.string,
    displayName: types.string,
    description: types.string,
    contactName: types.string,
    contactPhoneNumber: types.string,
    contactEmailAddress: types.string,
    variableListVersion: types.maybe(types.number, null),
    publishedVersionId: types.maybe(types.number, null),
    editingVersionId: types.number,
    state: types.string,
    mode: types.maybe(types.string, StudyMode.Default),
    compliance: types.optional(types.number, 0),
    lastUploadTimestamp: types.maybe(types.string),
    ownerId: types.maybe(types.string, null),
    lastSyncTimestamp: types.maybe(types.string),

    forcedGlobalScheduleType: types.optional(types.string, ''),
    alertThresholds: types.optional(AlertThresholdsModel, () => {
      return AlertThresholdsModel.create()
    })
  })
  .views(self => ({
    get publishedVersionKey() {
      if (!self.publishedVersionId) {
        return null
      } else {
        return `v${self.publishedVersionId}`
      }
    },
    get editingVersionName() {
      return `v${self.editingVersionId}`
    },
    get isEditing() {
      return self.publishedVersionId === null || self.editingVersionId > self.publishedVersionId
    }
  }))
  .actions(self => ({
    update(data) {
      applySnapshot(self, data)
    }
  }))

// STUDY VERSION

export const StudyVersionModel = types
  .model('StudyVersionModel', {
    id: types.number
  })
  .views(self => ({
    get versionKey() {
      return `v${self.id}`
    }
  }))

  .actions(self => ({
    setVersionData(data) {
      self.id = data.id
    }
  }))

export const StudiesModel = types
  .model('StudiesModel', {
    studies: types.map(StudyModel),
    connectedStudyId: types.maybe(types.string, null),
    lastConnectedStudyId: types.maybe(types.string, null)
  })
  .views(self => ({
    get hasStudies() {
      return self.studies.size > 0
    },
    get connectedStudy() {
      return self.studies.get(self.connectedStudyId)
    }
  }))
  .actions(self => ({
    setConnectedStudy(studyId) {
      if (self.connectedStudyId) {
        self.lastConnectedStudyId = self.connectedStudyId
      }

      self.connectedStudyId = studyId
    },

    setStudies(studies) {
      self.studies.merge(studies)
    },

    updateStudy(studyId, studyData) {
      self.studies.set(studyId, studyData)
    }
  }))

/**
 * Checks if user is an "owner" of a study. When there are no owners set for a study, all admins are an "owner".
 * @param study study to check
 * @param userId ID of the user of the study
 * @return {boolean} true if user is study owner, false otherwise
 */
export const isStudyOwner = (study, userId) => {
  return study.ownerId ? userId === study.ownerId : true
}
