export const QuestionType = {
  Choice: 'choice',
  XY: 'xy',
  Text: 'text',
  Slider: 'slider',
  ChoiceSlider: 'choice-slider',
  EvaluateVariable: 'evaluate-variable',
  SampleLocation: 'sample-location',
  SampleAudio: 'sample-audio',
  SampleVideo: 'sample-video'
}
