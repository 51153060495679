import { action, observable } from 'mobx'

export default class AuthStore {
  @observable isSignedIn = false
  @observable isReady = false

  constructor(firebase, onSignIn, onAuthTokenChanged) {
    this.firebase = firebase

    //  Watch for auth changes
    firebase.auth().onAuthStateChanged(user => {
      const wasSignedIn = this.isSignedIn

      if (user && !this.isSignedIn) {
        // Signed in
        this.isSignedIn = true
      } else if (!user && this.isSignedIn) {
        // Signed out
        this.isSignedIn = false
      }

      this.isReady = true
      onSignIn(user, wasSignedIn, this.isSignedIn)
    })

    firebase.auth().onIdTokenChanged(user => {
      onAuthTokenChanged(user)
    })
  }

  @action signIn(email, password) {
    return this.firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        this.isSignedIn = true
        return user
      })
  }

  @action signOut() {
    return this.firebase
      .auth()
      .signOut()
      .then(() => {
        this.isSignedIn = false
      })
  }

  @action forgotPassword(email) {
    return this.firebase.auth().sendPasswordResetEmail(email)
  }
}
