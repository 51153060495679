import { types, applySnapshot } from 'mobx-state-tree'

// PARTICIPANT PROFILE
import { ParticipantModel, ParticipantState } from './participant_models'
import { AssessmentModel } from './assessment_models'
import { ScheduleModel } from './schedule_models'
import { StudyModel } from './study_models'

export const VARIABLE_LIST_VAR_PREFIX = '&lt;&lt;' // '<<' in HTML
export const VARIABLE_LIST_VAR_POSTFIX = '&gt;&gt;' // '>>' in HTML

export const ParticipantStudyStateModel = types
  .model('ParticipantStudyStateModel', {
    participant: ParticipantModel,
    participantHash: types.optional(types.string, ''),

    assessments: types.map(AssessmentModel),
    assessmentsHash: types.optional(types.string, ''),

    schedules: types.map(ScheduleModel),
    schedulesHash: types.optional(types.string, ''),

    study: StudyModel,
    studyHash: types.optional(types.string, ''),

    variableList: types.optional(types.map(types.map(types.string)), {}),
    variableListHash: types.optional(types.string, '')
  })
  .views(self => ({
    get isActive() {
      return self.study.state === 'active' && self.participant.state === 'active'
    }
  }))
  .actions(self => ({
    deleteSurvey(surveyId) {
      self.assessments.delete(surveyId)
    }
  }))

export const ParticipantStateModel = types
  .model('ParticipantStateModel', {
    studies: types.map(ParticipantStudyStateModel)
  })
  .views(self => ({
    get activeStudies() {
      return Array.from(self.studies.values()).filter(study => {
        return study.study.state === 'active' && study.participant.state === ParticipantState.Active
      })
    },

    get stoppedStudies() {
      return Array.from(self.studies.values()).filter(study => {
        return study.study.state !== 'active' || study.participant.state !== ParticipantState.Active
      })
    },

    get studyIds() {
      return Array.from(self.studies.values()).map(study => {
        return study.study.id
      })
    }
  }))
  .actions(self => ({
    hasStudy(id) {
      return self.studies.has(id)
    },
    clearStudies() {
      self.studies.clear()
    },
    addStudy(data) {
      const studyDashboard = ParticipantStudyStateModel.create(data)
      self.studies.set(studyDashboard.study.id, studyDashboard)
      return studyDashboard
    },
    updateStudy(data) {
      let studyDashboard = self.studies.get(data.study.id)

      const oldParticipantHash = studyDashboard.participantHash
      const oldAssessmentsHash = studyDashboard.assessmentsHash
      const oldSchedulesHash = studyDashboard.schedulesHash
      const oldStudyHash = studyDashboard.studyHash

      const existingSurveyIds = Array.from(studyDashboard.assessments.values()).map(item => item.id)
      const existingScheduleIds = Array.from(studyDashboard.schedules.values()).map(item => item.id)

      applySnapshot(studyDashboard, data)

      const participantDidChange = oldParticipantHash !== studyDashboard.participantHash
      const assessmentsDidChange = oldAssessmentsHash !== studyDashboard.assessmentsHash
      const schedulesDidChange = oldSchedulesHash !== studyDashboard.schedulesHash
      const studyDidChange = oldStudyHash !== studyDashboard.studyHash

      const currentSurveyIds = new Set(Array.from(studyDashboard.assessments.values()).map(item => item.id))
      const currentScheduleIds = new Set(Array.from(studyDashboard.schedules.values()).map(item => item.id))

      const removedSurveyIds = assessmentsDidChange
        ? existingSurveyIds.filter(itemId => !currentSurveyIds.has(itemId))
        : []
      const removedScheduleIds = schedulesDidChange
        ? existingScheduleIds.filter(itemId => !currentScheduleIds.has(itemId))
        : []

      return {
        foo: studyDashboard,
        participantDidChange,
        assessmentsDidChange,
        removedSurveyIds,
        schedulesDidChange,
        removedScheduleIds,
        studyDidChange
      }
    },
    removeStudy(studyId) {
      self.studies.delete(studyId)
    },
    disableAlertForStudy(studyId, alertId) {
      const study = self.studies.get(studyId)
      study.disableScheduledAlert(alertId)
    }
  }))
