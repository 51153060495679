import { types } from 'mobx-state-tree'

// General
export const GeneralModel = types.model('GeneralModel', {
  systemNotification: types.string
}).actions(self => ({
  setSystemNotification(message) {
    self.systemNotification = message
  }
}))

