import { types } from 'mobx-state-tree'

// ADMIN
export const AdminModel = types.model('AdminModel', {
  userId: types.identifier(),
  displayName: types.string,
  contactEmailAddress: types.string,
  contactPhoneNumber: types.string,
  emailPreference: types.optional(types.map(types.boolean), {}),
  termsAgreed: types.optional(types.boolean, false)
})

// ADMINS
export const AdminListModel = types
  .model('AdminListModel', {
    items: types.array(AdminModel)
  })
  .actions(self => ({
    setItems(items) {
      self.items.replace(items)
    },
    removeItem(id) {
      self.items.remove(self.items.find(item => item.userId === id))
    },
  }))
