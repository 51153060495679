import { types } from 'mobx-state-tree'

export const EventModel = types
  .model('EventModel', {
    id: types.identifier(),
    originType: types.string,
    timestamp: types.string,
    timestampUnix: types.number,
    studyId: types.string,
    eventType: types.string,
    eventSubType: types.maybe(types.string),
    authorUserId: types.string,
    data: types.maybe(types.string)
  })
  .views(() => ({}))
  .actions(() => ({}))

export const EventsModel = types
  .model('EventModel', {
    events: types.map(EventModel)
  })
  .views(() => ({}))
  .actions(self => ({
    set(items) {
      self.events.replace(items)
    }
  }))
