import { types } from 'mobx-state-tree'

/* Default values to be used when exporting data with missed or skipped responses */
export const DEFAULT_EMPTY_RESPONSE_VALUE = '<no-response>'
export const DEFAULT_SKIPPED_RESPONSE_VALUE = '<not-shown>'

export const ExportTaskState = {
  Idle: 'idle',
  Preparing: 'preparing',
  Exporting: 'exporting',
  Success: 'success',
  Failure: 'failure'
}

// EXPORT

export const ExportTaskModel = types
  .model('ExportTaskModel', {
    id: types.string,
    timezoneName: types.string,
    userId: types.string,
    studyId: types.string,
    addedTimestampUnix: types.number,
    outputFilename: types.string,
    state: types.string,
    exportProgress: types.number,
    downloadUrl: types.maybe(types.string),
    filteredParticipantIds: types.maybe(types.array(types.string)),
    filteredSurveyIds: types.maybe(types.array(types.string)),
    filteredStudyVersions: types.maybe(types.array(types.number)),
    emptyResponseValue: types.optional(
      types.string,
      DEFAULT_EMPTY_RESPONSE_VALUE
    ),
    skippedResponseValue: types.optional(
      types.string,
      DEFAULT_SKIPPED_RESPONSE_VALUE
    )
  })
  .views(self => ({
    get didSucceed() {
      return (
        self.state === ExportTaskState.Success && self.downloadUrl.length > 0
      )
    },
    get isRunning() {
      return (
        self.state === ExportTaskState.Preparing ||
        self.state === ExportTaskState.Exporting
      )
    }
  }))
  .actions(self => ({
    reset() {
      self.state = ExportTaskState.Idle
      self.exportProgress = 0
      self.downloadUrl = ''
    }
  }))
