import { types } from 'mobx-state-tree'

export const TextQuestionConditionalModel = types
  .model('TextQuestionConditionalModel', {
    id: types.identifier(),
    type: types.literal('text'),
    command: types.string,
    containerId: types.string,
    comparator: types.string,
    value: types.string
  })
  .views(self => ({
    get description() {
      return `Branch when response includes '${self.value}'`
    }
  }))
  .actions(self => ({
    evaluate(response) {
      if (
        response.responseText.toLowerCase().includes(self.value.toLowerCase())
      ) {
        return self.command
      }
      return null
    }
  }))

export const ChoiceQuestionConditionalModel = types
  .model('ChoiceQuestionConditionalModel', {
    id: types.identifier(),
    type: types.literal('choice'),
    command: types.string,
    containerId: types.string,
    comparator: types.string,
    selectedChoices: types.map(types.boolean)
  })
  .views(self => ({
    get description() {
      const labels = self.selectedChoices.keys().join()
      return `Branch when response includes any of [${labels}]`
    }
  }))
  .actions(self => ({
    evaluate(response) {
      const keys = response.selectedChoices.keys()
      for (let key of keys) {
        if (self.selectedChoices.has(key)) {
          return self.command
        }
      }
      return null
    }
  }))

export const SliderQuestionConditionalModel = types
  .model('SliderQuestionConditionalModel', {
    id: types.identifier(),
    type: types.literal('slider'),
    command: types.string,
    containerId: types.string,
    minValueComparator: types.string,
    maxValueComparator: types.string,
    minValue: types.number,
    maxValue: types.number
  })
  .views(self => ({
    get description() {
      return `Branch when response is between ${self.minValue} and ${
        self.maxValue
      }`
    }
  }))
  .actions(self => ({
    evaluate(response) {
      if (
        response.responseValue >= self.minValue &&
        response.responseValue <= self.maxValue
      ) {
        return self.command
      }

      return null
    }
  }))

export const ChoiceSliderQuestionConditionalModel = types
  .model('ChoiceSliderQuestionConditionalModel', {
    id: types.identifier(),
    type: types.literal('choice-slider'),
    command: types.string,
    containerId: types.string,
    comparator: types.string,
    selectedChoices: types.map(types.boolean),
    minValueComparator: types.maybe(types.string),
    maxValueComparator: types.maybe(types.string),
    minValue: types.maybe(types.number),
    maxValue: types.maybe(types.number)
  })
  .views(self => ({
    get description() {
      const labels = self.selectedChoices.keys().join()
      return `Branch when response includes any of [${labels}] and is between ${
        self.minValue
      } and ${self.maxValue}`
    }
  }))
  .actions(self => ({
    evaluate(response) {
      const keys = response.selectedChoices.keys()
      for (let key of keys) {
        if (self.selectedChoices.has(key)) {
          if (
            response.selectedChoiceValues.get(key) >= self.minValue &&
            response.selectedChoiceValues.get(key) <= self.maxValue
          ) {
            return self.command
          }
        }
      }
      return null
    }
  }))

export const XYQuestionConditionalModel = types
  .model('XYQuestionConditionalModel', {
    id: types.identifier(),
    type: types.literal('xy'),
    command: types.string,
    containerId: types.string,
    comparator: types.string,
    xMinValue: types.number,
    xMaxValue: types.number,
    yMinValue: types.number,
    yMaxValue: types.number
  })
  .views(self => ({
    get description() {
      return `Branch when response is within X ${self.xMinValue} - ${
        self.xMaxValue
      } and Y ${self.yMinValue} - ${self.yMaxValue}`
    }
  }))
  .actions(self => ({
    evaluate(response) {
      if (
        response.selectedX >= self.xMinValue &&
        response.selectedX <= self.xMaxValue &&
        response.selectedY >= self.yMinValue &&
        response.selectedY <= self.yMaxValue
      ) {
        return self.command
      }

      return null
    }
  }))

export const EvaluateVariableConditionalModel = types
  .model('EvaluateVariableConditionalModel', {
    id: types.identifier(),
    type: types.literal('evaluate-variable'),
    command: types.string,
    containerId: types.string,
    variableId: types.string,
    comparator: types.string,
    value: types.string
  })
  .views(self => ({
    get description() {
      return `Branch when variable ${self.variableId} ${self.comparator} ${
        self.value
      }`
    }
  }))
  .actions(() => ({
    evaluate(response) {
      console.log(response)
      return null
    }
  }))
