import React from 'react'
import Spinner from 'react-spinkit'

const Loader = ({ message }) => (
  <div className="container-centered">
    <div className="loader">
      <div className="loader-inner">
        <Spinner name="line-scale-pulse-out" fadeIn="none" />{' '}
        <span className="loader-text">
          SEMA<sup>3</sup>
        </span>
      </div>
      <div className="loader-message">{message ? message : null}</div>
    </div>
  </div>
)

export default Loader
