import { types } from 'mobx-state-tree'

import { ParticipantModel } from './participant_models'

// ADMINS

export const DashboardModel = types
  .model('DashboardModel', {
    activeParticipants: types.array(ParticipantModel)
  })
  .actions(self => ({
    setActiveParticipants(items) {
      self.activeParticipants.replace(items)
    }
  }))
