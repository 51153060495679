import { types } from 'mobx-state-tree'
import { DateTime } from 'luxon'

export const ScheduleType = {
  Weekly: 'weekly',
  DayIndex: 'day-index',
  AbsoluteDate: 'absolute-date'
}

// ScheduleItemModel
export const ScheduleItemModel = types
  .model('ScheduleItemModel', {
    id: types.string,
    startOffsetDays: types.number,
    dayOffsetMins: types.number,
    alertIntervalMins: types.number,
    alertExpiryMins: types.number,
    randomiseProbability: types.maybe(types.number),

    layoutRow: types.optional(types.number, 0),
    layoutCol: types.optional(types.number, 0),
    layoutTime: types.optional(types.Date, new Date()),
    layoutTimeLabel: types.optional(types.string, '')
  })
  .actions(self => ({
    setLayoutRowCol(row, col, rowIntervalMins) {
      self.layoutRow = row
      self.layoutCol = col

      const offsetFromZeroMins = row * rowIntervalMins
      const startDate = DateTime.fromObject({
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
      })
      const startTime = startDate.plus({ minute: offsetFromZeroMins })
      const endTime = startTime.plus({ minute: self.alertIntervalMins })

      self.layoutTime = startTime.toJSDate()
      self.layoutTimeStartLabel = startTime.toLocaleString(DateTime.TIME_24_SIMPLE)
      self.layoutTimeEndLabel = endTime.toLocaleString(DateTime.TIME_24_SIMPLE)
    },

    setOffsets(days, minutes) {
      self.startOffsetDays = days
      self.dayOffsetMins = minutes
    },

    setDayOffsetsMinutes(minutes) {
      self.dayOffsetMins = minutes
    },

    setAlertIntervalMins(mins) {
      self.alertIntervalMins = mins
    },

    setAlertExpiryMins(mins) {
      self.alertExpiryMins = mins
    },

    setRandomiseProbability(probability) {
      self.randomiseProbability = probability
    }
  }))

// ScheduleModel
export const ScheduleModel = types
  .model('ScheduleModel', {
    id: types.identifier(),
    displayName: types.string,
    scheduleType: types.enumeration('ScheduleType', [
      ScheduleType.Weekly,
      ScheduleType.DayIndex,
      ScheduleType.AbsoluteDate
    ]),
    items: types.map(ScheduleItemModel)
  })
  .views(self => {
    return {
      get isEmpty() {
        return self.items.size === 0
      }
    }
  })
  .actions(self => ({
    calculateBaseDateFromParticipantStartDate(startDate) {
      if (self.scheduleType === ScheduleType.Weekly) {
        // Use the participants start date but reset to start of that week
        return startDate.startOf('week').startOf('day')
      } else if (self.scheduleType === ScheduleType.DayIndex) {
        // Use the plain participant start date
        return startDate.startOf('day')
      } else if (self.scheduleType === ScheduleType.AbsoluteDate) {
        // All offsets are from this absolute date
        return DateTime.fromObject({ year: 2018, month: 1, day: 1 }).startOf('day')
      }
    },

    calculateVisualBaseDateFromBaseDate(baseDate) {
      if (self.scheduleType === ScheduleType.Weekly) {
        return baseDate
      } else if (self.scheduleType === ScheduleType.DayIndex) {
        return baseDate
      } else if (self.scheduleType === ScheduleType.AbsoluteDate) {
        return DateTime.local().startOf('day')
      }
    },

    clearScheduleItems() {
      self.items.clear()
    },

    addScheduleItem(id, startOffsetDays, dayOffsetMins, alertIntervalMins, alertExpiryMins) {
      const item = ScheduleItemModel.create({
        id,
        startOffsetDays,
        dayOffsetMins,
        alertIntervalMins,
        alertExpiryMins
      })

      self.items.set(id, item)

      return item
    },
    removeScheduleItem(id) {
      self.items.delete(id)
    },
    setScheduleType(type) {
      self.scheduleType = type
    },
    setDisplayName(name) {
      self.displayName = name
    },

    convertOffsetsToRowColumns(baseDate, visualBaseDate, rowIntervalMins, startOffsetDays, dayOffsetMins) {
      const visualDiffDays = Math.floor(visualBaseDate.diff(baseDate).as('day'))

      const offsetDate = baseDate.plus({
        days: startOffsetDays,
        minutes: dayOffsetMins
      })

      const diffDays = Math.floor(offsetDate.diff(baseDate).as('day'))
      const column = diffDays - visualDiffDays
      const row = Math.floor(dayOffsetMins / rowIntervalMins)

      // console.log(`Days ${startOffsetDays} Mins ${dayOffsetMins} -> Row ${row} Col ${column} - DiffDays ${diffDays} VDiffDays ${visualDiffDays}`);

      return { row, column }
    },

    convertRowColToStartOffsets(baseDate, visualBaseDate, rowIntervalMins, row, column) {
      const visualDiffDays = visualBaseDate.diff(baseDate).as('day')

      const startOffsetDays = Math.floor(column + visualDiffDays)
      const dayOffsetMins = row * rowIntervalMins

      // console.log(`Row ${row} Col ${column} -> Days ${startOffsetDays} Mins ${dayOffsetMins}`);

      return { startOffsetDays, dayOffsetMins }
    }
  }))

// Schedules Model
export const SchedulesModel = types
  .model('SchedulesModel', {
    schedules: types.map(ScheduleModel)
  })
  .views(self => ({
    get length() {
      return self.schedules.length
    },
    get schedulesArray() {
      return self.schedules.values()
    }
  }))
  .actions(self => ({
    setSchedules(schedules) {
      self.schedules.replace(schedules)
    },
    updateSchedule(item) {
      self.schedules.set(item.id, item)
    },
    removeSchedule(id) {
      self.schedules.delete(id)
    },
    get(id) {
      return self.schedules.get(id)
    }
  }))
