import { action, observable, runInAction } from 'mobx'
import { applySnapshot } from 'mobx-state-tree'
import { InviteTypes } from '../shared_models/invite_models'
import { ParticipantState } from '../shared_models/participant_models'
import { ParticipantStateModel } from '../shared_models/participant_state_models'

export default class DataStore {
  @observable app = null
  @observable adminProfile = null

  @observable isLoadingStudies = false
  @observable isProcessing = false

  @observable connectedStudy = null
  @observable lastConnectedStudyId = null

  @observable studyLoaded = false
  @observable studyVersionLoaded = false
  @observable participantsLoaded = false
  @observable pendingParticipantsLoaded = false
  @observable assessmentsLoaded = false
  @observable schedulesLoaded = false
  @observable adminsLoaded = false
  @observable variablesLoaded = false
  @observable eventsLoaded = false
  @observable systemNotificationLoaded = false

  constructor(
    firebase,
    api,
    studiesModel,
    studyModel,
    studyVersionModel,
    participantsModel,
    schedulesModel,
    assessmentsModel,
    adminListModel,
    dashboardModel,
    variablesModel,
    exportTaskModel,
    eventsModel,
    generalModel
  ) {
    this.db = firebase.firestore()

    // Misc
    this.studyRef = null
    this.studyVersionWatcher = null
    this.exportTaskWatcher = null
    this.participantsRef = null
    this.adminsRef = null

    // Models
    this.api = api
    this.studiesModel = studiesModel
    this.studyModel = studyModel
    this.participantsModel = participantsModel
    this.schedulesModel = schedulesModel
    this.assessmentsModel = assessmentsModel
    this.studyVersionModel = studyVersionModel
    this.adminListModel = adminListModel
    this.dashboardModel = dashboardModel
    this.variablesModel = variablesModel
    this.exportTaskModel = exportTaskModel
    this.eventsModel = eventsModel
    this.generalModel = generalModel
  }

  connectToServer = user => {
    const userId = user.uid

    console.log(`Connected to server with userId ${userId}`)

    this.db
      .collection('admin_profiles')
      .doc(userId)
      .onSnapshot(
        snap => {
          this.adminProfile = snap.data()
          this.getStudiesForCurrentUser()
          this.getSystemNotification()
        },
        err => {
          console.log('Error getting user profile: ', err)
        }
      )

    this.watchDashboard(userId)
  }

  disconnectFromServer = () => {
    console.log('Disconnected from server.')
    this.studyVersionLoaded = false
    this.participantsLoaded = false
    this.schedulesLoaded = false
    this.assessmentsLoaded = false
    this.adminsLoaded = false
    this.studyLoaded = false
    this.eventsLoaded = false
  }

  connectToStudy = studyId => {
    this.disconnectFromStudy()

    this.watchStudy(studyId)
    this.watchParticipants(studyId)
    this.watchPendingParticipants(studyId)
    this.watchAdmins(studyId)
    this.watchVariables(studyId)
    this.watchEvents(studyId)
  }

  watchStudy = studyId => {
    this.studyRef = this.db
      .collection('studies')
      .doc(studyId)
      .onSnapshot(snap => {
        let studyData = snap.data()
        studyData.id = studyId

        this.studyModel.update(studyData)
        this.studyLoaded = true

        this.watchStudyVersion(this.studyModel)
      })
  }

  watchStudyVersion = study => {
    if (this.studyVersionWatcher) {
      this.studyVersionWatcher()
    }

    const studyId = study.id
    const versionKey = study.editingVersionName

    // Schedules
    this.studyVersionWatcher = this.db
      .collection('studies')
      .doc(studyId)
      .collection('versions')
      .doc(versionKey)
      .onSnapshot(snap => {
        const data = snap.data()
        this.studyVersionModel.setVersionData(data)
        this.studyVersionLoaded = true

        this.loadAssessments(studyId, this.studyVersionModel.versionKey)
        this.loadSchedules(studyId, this.studyVersionModel.versionKey)
      })
  }

  watchParticipants = studyId => {
    this.participantsRef = this.db
      .collection('studies')
      .doc(studyId)
      .collection('participants')
      .onSnapshot(snap => {
        let items = {}
        snap.forEach(doc => {
          const data = doc.data()
          items[data.participantId] = data
        })

        this.participantsModel.setParticipants(items)
        this.participantsLoaded = true
      })
  }

  watchPendingParticipants = studyId => {
    this.participantInvitesRef = this.db
      .collection('participant_invites')
      .where('studyId', '==', studyId)
      .where('inviteType', '==', InviteTypes.Participant)
      .onSnapshot(snap => {
        let items = {}
        snap.forEach(doc => {
          const data = doc.data()
          items[data.participantId] = data
        })

        this.participantsModel.setPendingParticipants(items)
        this.pendingParticipantsLoaded = true
      })
  }

  getPendingParticipants = studyId => {
    this.participantsRef = this.db
      .collection('studies')
      .doc(studyId)
      .collection('participants')
      .onSnapshot(snap => {
        let items = {}
        snap.forEach(doc => {
          const data = doc.data()
          items[data.participantId] = data
        })

        this.participantsModel.setParticipants(items)
        this.participantsLoaded = true
      })
  }

  watchAdmins = studyId => {
    this.adminsRef = this.db
      .collection('studies')
      .doc(studyId)
      .collection('admins')
      .onSnapshot(snap => {
        let items = []
        snap.forEach(doc => {
          items.push(doc.data())
        })

        this.adminListModel.setItems(items)
        this.adminsLoaded = true
      })
  }

  watchDashboard = userId => {
    this.activeParticipantsRef = this.db
      .collection('admin_dashboards')
      .doc(userId)
      .collection('participants')
      .onSnapshot(snap => {
        let items = []
        snap.forEach(doc => {
          const participant = doc.data()
          if (participant.state === ParticipantState.Active) {
            items.push(participant)
          }
        })

        this.dashboardModel.setActiveParticipants(items)
      })
  }

  watchExportTask = (studyId, taskId) => {
    if (this.exportTaskWatcher) {
      this.exportTaskWatcher()
    }

    this.exportTaskModel.reset()

    this.exportTaskWatcher = this.db
      .collection('studies')
      .doc(studyId)
      .collection('exports')
      .doc(taskId)
      .onSnapshot(snap => {
        const data = snap.data()
        applySnapshot(this.exportTaskModel, data)
      })
  }

  getParticipantSchedule = (studyId, participantId) => {
    return this.db
      .collection('schedules')
      .where('studyId', '==', studyId)
      .where('participantId', '==', participantId)
      .orderBy('expiryTimestampUnix')
      .limit(20)
      .get()
      .then(snap => {
        let items = []
        snap.forEach(doc => {
          const data = doc.data()
          items.push(data)
        })

        return items
      })
  }

  /**
   * Get participant data in a study
   * @param participantId - the participant id
   * @param studyId - the study id
   * @returns {Promise<firebase.firestore.DocumentData | never>} - participant data if exists
   */
  getParticipant = (participantId, studyId) => {
    return this.db
      .collection('studies')
      .doc(studyId)
      .collection('participants')
      .doc(participantId)
      .get()
      .then(snap => {
        return snap.data()
      })
  }

  /**
   * Get an array of all active studies of the participant
   * @param participantId - participant id to get
   */
  getParticipantActiveStudies = participantId => {
    return this.db
      .collection('participants')
      .doc(participantId)
      .collection('studies')
      .get()
      .then(snap => {
        const participantStateModel = ParticipantStateModel.create({
          studies: {}
        })
        snap.docs.forEach(studySnap => {
          participantStateModel.addStudy(studySnap.data())
        })

        return participantStateModel.activeStudies
      })
  }

  loadAssessments = (studyId, versionKey) => {
    // Schedules
    this.db
      .collection('studies')
      .doc(studyId)
      .collection('versions')
      .doc(versionKey)
      .collection('assessments')
      .orderBy('displayName', 'asc')
      .get()
      .then(snap => {
        let items = {}
        snap.forEach(doc => {
          const data = doc.data()
          items[data.id] = data
        })

        this.assessmentsModel.mergeAssessments(items)
        this.assessmentsLoaded = true
      })
  }

  watchVariables = studyId => {
    // Schedules
    this.db
      .collection('studies')
      .doc(studyId)
      .collection('variables')
      .onSnapshot(snap => {
        let items = {}
        snap.forEach(doc => {
          const data = doc.data()
          items[data.name] = data
        })

        this.variablesModel.mergeVariables(items)
        this.variablesLoaded = true
      })
  }

  watchEvents = studyId => {
    // Schedules
    this.db
      .collection('studies')
      .doc(studyId)
      .collection('events')
      .orderBy('timestampUnix', 'desc')
      .onSnapshot(snap => {
        let items = {}
        snap.forEach(doc => {
          const data = doc.data()
          items[data.id] = data
        })

        this.eventsModel.set(items)
        this.eventsLoaded = true
      })
  }

  /**
   * Load Responses of a study with Filter and Pagination
   * @param studyId - study ID to load
   * @param filteredSurveyIds - filter by surveys Ids (single)
   * @param filteredParticipantIds - filter by participant Ids (multiple)
   * @param filteredVersions - filter by version (single)
   * @param offsetSnap - response's snapshot to offset (for pagination)
   * @param limit - total limit number of responses to load
   * @returns {Promise} - array of responses snapshots
   */
  loadResponses = (studyId, filteredSurveyIds, filteredParticipantIds, filteredVersions, offsetSnap, limit) => {
    return new Promise((resolve, reject) => {
      let responsesRef = this.db
        .collection('studies')
        .doc(studyId)
        .collection('responses')

      if (filteredSurveyIds.length === 1) {
        responsesRef = responsesRef.where('assessmentId', '==', filteredSurveyIds[0])
      }

      if (filteredParticipantIds.length > 0) {
        responsesRef = responsesRef.where('participantId', 'in', filteredParticipantIds)
      }

      if (filteredVersions.length === 1) {
        responsesRef = responsesRef.where('studyVersion', '==', filteredVersions[0])
      }

      responsesRef = responsesRef.orderBy('completedTimestampUnix', 'desc')

      if (offsetSnap) {
        responsesRef = responsesRef.startAfter(offsetSnap)
      }

      responsesRef
        .limit(limit)
        .get()
        .then(snap => {
          resolve(snap.docs)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  /**
   * Load all responses of the participant in a study
   *
   * @param studyId - the study to load from
   * @param participantId - the participant, author of the requested responses
   * @returns {Promise<Array | never>} array of all requested responses
   */
  loadParticipantResponses = (studyId, participantId) => {
    const responsesRef = this.db
      .collection('studies')
      .doc(studyId)
      .collection('responses')
      .where('participantId', '==', participantId)

    return responsesRef.get().then(snap => {
      let responses = []
      snap.forEach(doc => {
        responses.push(doc.data())
      })

      return responses
    })
  }

  loadResponse = (studyId, responseId) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection('studies')
        .doc(studyId)
        .collection('responses')
        .doc(responseId)
        .get()
        .then(snap => {
          resolve(snap.data())
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  loadSchedules = (studyId, versionKey) => {
    // Schedules
    this.db
      .collection('studies')
      .doc(studyId)
      .collection('versions')
      .doc(versionKey)
      .collection('schedules')
      .orderBy('displayName', 'asc')
      .get()
      .then(snap => {
        let items = {}
        snap.forEach(doc => {
          const data = doc.data()
          items[data.id] = data
        })

        this.schedulesModel.setSchedules(items)
        this.schedulesLoaded = true
      })
  }

  loadDataset = (studyId, participantId, datasetId) => {
    const dataSetRef = this.db
      .collection('studies')
      .doc(studyId)
      .collection('participants')
      .doc(participantId)
      .collection('datasets')
      .doc(datasetId)
    const valuesRef = dataSetRef.collection('values').orderBy('timestamp', 'asc')

    // Datasets
    return Promise.all([dataSetRef.get(), valuesRef.get()]).then(res => {
      let dataSet = res[0].data()
      const valuesSnap = res[1]

      let values = []
      valuesSnap.forEach(doc => {
        const value = doc.data()
        values.push(value)
      })

      dataSet.values = values

      return dataSet
    })
  }

  loadDatasets = (studyId, participantId) => {
    const datasetsRef = this.db
      .collection('studies')
      .doc(studyId)
      .collection('participants')
      .doc(participantId)
      .collection('datasets')

    return datasetsRef.get().then(datasetsSnap => {
      let values = []
      datasetsSnap.forEach(doc => {
        const value = doc.data()
        values.push(value)
      })

      return values
    })
  }

  loadVariableList = studyId => {
    const variableListRef = this.db
      .collection('studies')
      .doc(studyId)
      .collection('variableList')

    return variableListRef.get().then(variableListSnap => {
      let values = []
      variableListSnap.forEach(doc => {
        const value = doc.data()
        values.push(value)
      })

      // return values
      return values[0] // TODO RIO: Handle multiple versions
    })
  }

  disconnectFromStudy = () => {
    // Unwatch any existing watcher
    if (this.studyRef) {
      this.studyRef()
      this.studyRef = null
    }

    if (this.studyVersionWatcher) {
      this.studyVersionWatcher()
      this.studyVersionWatcher = null
    }

    if (this.participantsRef) {
      this.participantsRef()
      this.participantsRef = null
    }

    this.studyLoaded = false
    this.studyVersionLoaded = false
    this.participantsLoaded = false
    this.assessmentsLoaded = false
    this.schedulesLoaded = false
    this.adminsLoaded = false
  }

  @action
  getStudiesForCurrentUser = () => {
    let getStudies = []
    let studies = {}

    this.isLoadingStudies = true
    this.isProcessing = true

    if (this.adminProfile.studies) {
      // For each study in the userProfile.studies list
      Object.keys(this.adminProfile.studies).forEach(studyId => {
        const getStudy = this.db
          .collection('studies')
          .doc(studyId)
          .get()
          .then(snap => {
            if (snap.exists) {
              const studyData = snap.data()
              studyData.id = studyId
              studies[studyId] = studyData
            }
          })
          .catch(err => {
            console.log('Error getting documents: ', err)
          })

        getStudies.push(getStudy)
      })
    }

    return Promise.all(getStudies).then(() => {
      this.studiesModel.setStudies(studies)
      this.isLoadingStudies = false
      this.isProcessing = false
    })
  }

  /**
   * Fetch system notification
   * @return {Promise<unknown>}
   */
  @action
  getSystemNotification = () => {
    this.db.collection('general').get().then((res) => {
      const docs = res.docs
      const formatted = docs.map(each => each.data().systemNotification)
      this.generalModel.setSystemNotification(formatted[0] || '')
    }).catch(err => {
      console.log('Error getting general info: ', err)
    })
  }

  /**
   * Set has system notification loaded
   * @param {Boolean} val
   */
  @action
  setHasSystemNotificationNotificationLoaded = (val) => {
    this.systemNotificationLoaded = val
  }

  createNewStudy = (
    name,
    description,
    contactName,
    contactPhoneNumber,
    contactEmailAddress,
    forcedGlobalScheduleType,
    cloneStudyId
  ) => {
    this.isProcessing = true

    return this.api
      .post('/createNewStudy/', {
        displayName: name,
        description,
        contactName,
        contactPhoneNumber,
        contactEmailAddress,
        forcedGlobalScheduleType,
        cloneStudyId
      })
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
        return { id: res.data.id }
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  createNewVersion = studyId => {
    this.isProcessing = true

    return this.api
      .post('/createNewEditingVersion/', {
        studyId: studyId
      })
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
        return { id: res.data.id }
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  publishNewVersion = studyId => {
    this.isProcessing = true

    return this.api
      .post('/publishCurrentEditingVersion/', {
        studyId: studyId
      })
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
        return { id: res.data.id }
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  createNewAssessment = (studyId, assessmentName, assessmentData) => {
    this.isProcessing = true

    return this.api
      .post('/createNewAssessment/', {
        studyId: studyId,
        assessmentName: assessmentName,
        assessmentData: assessmentData
      })
      .then(res => {
        this.assessmentsModel.updateAssessment(res.data)
        this.isProcessing = false
        return { id: res.data.id }
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  deleteAssessment = (studyId, surveyId) => {
    this.isProcessing = true

    return this.api
      .post('/deleteAssessment/', {
        studyId: studyId,
        surveyId: surveyId
      })
      .then(res => {
        this.assessmentsModel.removeAssessment(surveyId)
        this.isProcessing = false
        return { id: res.data.id }
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  deleteParticipants = (studyId, participantIds) => {
    this.isProcessing = true

    return this.api
      .post('/deleteStudyParticipant/', {
        studyId: studyId,
        participantIds: participantIds
      })
      .then(res => {
        this.participantsModel.removeParticipants(participantIds)
        this.isProcessing = false
        return { res }
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  deleteStudy = (studyId) => {
    this.isProcessing = true

    return this.api
      .post('/deleteStudy/', {
        studyId: studyId
      })
      .then(res => {
        this.isProcessing = false
        return { res }
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  /**
   * Send request to server to validate question's tag
   * @param studyId - study's id of the question in assessment (survey)
   * @param questionTag - the question tag text
   * @param questionId - the current question Id of the tag
   * @returns {*}
   */
  validateAssessmentQuestion = (studyId, questionTag, questionId) => {
    this.isProcessing = true

    return this.api
      .post('/validateAssessmentQuestion/', {
        studyId,
        questionTag,
        questionId
      })
      .then(res => {
        this.isProcessing = false
        return { id: res.data }
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  createNewSchedule = (studyId, scheduleName, scheduleType) => {
    this.isProcessing = true

    return this.api
      .post('/createNewSchedule/', {
        studyId,
        scheduleName,
        scheduleType
      })
      .then(res => {
        this.schedulesModel.updateSchedule(res.data)
        this.isProcessing = false
        console.log(res.data)
        return { id: res.data.id }
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  /**
   * Send request to server to delete schedule and update the 'ScheduleModel' on success
   * @param studyId - study id contains the deleted schedule
   * @param scheduleId - the schedule id to delete
   */
  deleteSchedule = (studyId, scheduleId) => {
    this.isProcessing = true

    return this.api
      .post('/deleteSchedule/', {
        studyId: studyId,
        scheduleId: scheduleId
      })
      .then(res => {
        this.schedulesModel.removeSchedule(scheduleId)
        this.loadAssessments(studyId, this.studyVersionModel.versionKey)
        this.isProcessing = false
        return { id: res.data.id }
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  deleteStudyAdmin = (studyId, adminId) => {
    this.isProcessing = true
    const data = { studyId: studyId, adminId: adminId }

    return this.api
      .post('/deleteStudyAdmin/', data)
      .then(res => {
        this.adminListModel.removeItem(adminId)
        this.isProcessing = false
        console.log(res.data)
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  getAvailableParticipantId = () => {
    this.isProcessing = true

    return this.api
      .post('/getAvailableParticipantId/', {})
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
        return { id: res.data.id }
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  inviteUsers = data => {
    this.isProcessing = true

    return this.api
      .post('/inviteUsers/', data)
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  validateParticipants = data => {
    this.isProcessing = true

    return this.api
      .post('/validateParticipants/', data)
      .then(res => {
        this.isProcessing = false
        return res.data
      })
      .catch(err => {
        this.isProcessing = false
        throw err
      })
  }

  activateAccount = (inviteToken, password1, password2, termsAgreed) => {
    this.isProcessing = true

    return this.api
      .post('/inviteActivate/', {
        inviteToken: inviteToken,
        password: password1,
        password2: password2,
        termsAgreed: termsAgreed
      })
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
        return res.data
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  updateParticipantState = (
    studyId,
    participantIds,
    state,
    permission,
    chartsPreview,
    startTimestamp,
    endTimestamp,
    randomiseProbability,
    performComplianceReset,
    surveyConfigs
  ) => {
    this.isProcessing = true

    return this.api
      .post('/updateParticipantState/', {
        studyId,
        participantIds,
        state,
        permission,
        chartsPreview,
        startTimestamp,
        endTimestamp,
        randomiseProbability,
        performComplianceReset,
        surveyConfigs
      })
      .then(res => {
        this.isProcessing = false
        return res.data
      })
      .catch(err => {
        this.isProcessing = false
        throw err
      })
  }

  /**
   * Send request to server to update participant's information (email)
   * @param participantId - participant's id to update
   * @param data - updating data ({email})
   */
  updateParticipantInfo = (participantId, data) => {
    this.isProcessing = true

    return this.api
      .post('/updateParticipantInfo/', { ...data, participantId })
      .then(res => {
        this.isProcessing = false
        return res.data
      })
      .catch(err => {
        this.isProcessing = false
        throw err
      })
  }

  /**
   *
   */
  updateVariableList = (studyId, variableList) => {
    this.isProcessing = true

    return this.api
      .post('/updateVariableList/', { studyId, variableList })
      .then(res => {
        this.isProcessing = false
        return res.data
      })
      .catch(err => {
        this.isProcessing = false
        throw err
      })
  }

  exportData = (
    studyId,
    timezoneName,
    filteredStudyVersions,
    filteredParticipantIds,
    filteredSurveyIds,
    emptyResponseValue,
    skippedResponseValue
  ) => {
    runInAction(() => {
      this.isProcessing = true
      this.isExporting = true
    })

    return this.api
      .post('/exportData/', {
        studyId,
        filteredStudyVersions,
        timezoneName,
        filteredParticipantIds,
        filteredSurveyIds,
        emptyResponseValue,
        skippedResponseValue
      })
      .then(res => {
        runInAction(() => {
          this.isProcessing = false
          this.isExporting = false
        })

        console.log(res.data)
        return { studyId: res.data.studyId, taskId: res.data.taskId }
      })
      .catch(err => {
        runInAction(() => {
          this.isProcessing = false
          this.isExporting = false
        })

        console.log(err)
        throw err
      })
  }

  syncSchedule = (studyId, versionId, data) => {
    if (!this.schedulesLoaded) {
      return Promise.resolve()
    }

    this.isProcessing = true

    return this.api
      .post('/studySyncSchedule/', { studyId, versionId, data })
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  syncAssessment = (studyId, versionId, data) => {
    if (!this.assessmentsLoaded) {
      return Promise.resolve()
    }

    this.isProcessing = true

    return this.api
      .post('/studySyncAssessment/', { studyId, versionId, data })
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  syncVariables = (studyId, versionId, data) => {
    if (!this.assessmentsLoaded) {
      return Promise.resolve()
    }

    this.isProcessing = true

    return this.api
      .post('/studySyncVariables/', { studyId, versionId, data })
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  checkToken = inviteToken => {
    return this.api
      .post('/inviteValidate/', { inviteToken })
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
        return res.data
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  createStudyVariable = (studyId, variableName, variableValueTypeId, variableValue) => {
    return this.api
      .post('/createStudyVariable/', {
        studyId,
        variableName,
        variableValueTypeId,
        variableValue
      })
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
        return res.data
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  deleteStudyVariable = (studyId, variableName) => {
    return this.api
      .post('/deleteStudyVariable/', { studyId, variableName })
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
        return res.data
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  saveStudySettings = (
    studyId,
    studyName,
    studyDescription,
    contactName,
    contactEmailAddress,
    contactPhoneNumber,
    forcedGlobalScheduleType,
    studyState,
    compliance,
    syncTime
  ) => {
    return this.api
      .post('/saveStudySettings/', {
        studyId,
        studyName,
        studyDescription,
        contactName,
        contactEmailAddress,
        contactPhoneNumber,
        forcedGlobalScheduleType,
        studyState,
        alertThresholds: { compliance, syncTime }
      })
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
        return res.data
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  /**
   * Send request to server to send charts code to user's email
   * @param data - {studyId, participantId, email}
   */
  sendChartsCode = data => {
    this.isProcessing = true

    // TODO: needs to replace verification token
    return this.api
      .post('/sendChartsCode/?token=123', data)
      .then(res => {
        this.isProcessing = false
        console.log(res.data)
      })
      .catch(err => {
        this.isProcessing = false
        console.log(err)
        throw err
      })
  }

  /**
   * Send request to server to update admin profile
   * @param adminId - admin ID
   * @param data - updating data
   */
  updateAdminProfile = (adminId, data) => {
    this.isProcessing = true
    return this.api
      .post('/updateAdminProfile/', { ...data, adminId })
      .then(res => {
        this.isProcessing = false
        return res.data
      })
      .catch(err => {
        this.isProcessing = false
        throw err
      })
  }
}
